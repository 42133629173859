import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { PubSub } from "./pubSub";

/**
 * Component to show a modal
 * Supply the topic name as property. Publish an object looking like this to the topic (see pubSub.js):
 *
 * {
 *  show : true
 *  title : '',
 *  text  : '',
 *  closeButtonText : ''
 * }
 */
class RehacatModal extends Component {
  subscription;

  constructor(props) {
    super(props);
    this.state = {
      title: "Hinweis",
      text: "",
      closeButtonText: "Ok",
      textAsHtml: false,
      modalOpen: false,
    };

    let pubSub = PubSub.getInstance();
    pubSub.subscribeToTopic(props.topic, (data) => {
      this.showModal(data);
    });
  }

  showModal = (data) => {
    this.setState({
      title: data.title,
      text: data.text,
      closeButtonText: data.closeButtonText,
      textAsHtml: data.textAsHtml,
      modalOpen: data.show,
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  componentWillUnmount() {
    let pubSub = PubSub.getInstance();
    pubSub.unsubscribe(this.subscription);
  }

  render() {
    let boxContent;
    if (this.state.textAsHtml) {
      boxContent = (
        <span dangerouslySetInnerHTML={{ __html: this.state.text }} />
      );
    } else {
      boxContent = this.state.text;
    }

    return (
      <Modal show={this.state.modalOpen} onHide={this.closeModal}>
        <ModalHeader closeButton>
          <ModalTitle>{this.state.title}</ModalTitle>
        </ModalHeader>
        <ModalBody>{boxContent}</ModalBody>
        <ModalFooter>
          <Button onClick={this.closeModal}>
            {this.state.closeButtonText}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default RehacatModal;
