/**
 * Note: This is a singleton class. Do not instantiate it directly, use static getInstance() method instead.
 * Implements a simple pub/sub functionality
 */
export class PubSub {
  instance;
  topics;

  constructor() {
    this.topics = {};
  }

  /**
   * Static method which returns the instance of this class since the class should not be instanced directly
   */
  static getInstance() {
    if (!this.instance) {
      this.instance = new PubSub();
    }

    return this.instance;
  }

  /**
   * Subscribe to the topic with the given name.
   * Returns the subscription object which is needed to unsubscribe again later.
   * @param {string} topicName
   * @param {function} callback The Function to call. It is called with all additional arguments given to publishToTopic
   */
  subscribeToTopic(topicName, callback) {
    let subscription = new Subscription(callback);
    this._getTopic(topicName).push(subscription);
    return subscription;
  }

  /**
   * Publishes to the given topic. All additional arguments are passed on to the callback function
   * @param {string} topicName
   * @param  {...any} data
   */
  publishToTopic(topicName, ...data) {
    let topic = this._getTopic(topicName);
    for (let i = 0; i < topic.length; i++) {
      topic[i].triggerCallback(data);
    }
  }

  /**
   * Unsubscribes from the given topic
   * @param {Subscription} subscription
   */
  unsubscribe(subscription) {
    for (let topic in this.topics) {
      if (!Array.isArray(this.topics[topic])) {
        break;
      }
      for (let i = 0; i < this.topics[topic].length; i++) {
        if (this.topics[topic][i] === subscription) {
          this.topics[topic].splice(i, 0);
        }
      }
    }
  }

  /**
   * Privat helper method which ensures that the topic with the given name exists and returns it.
   * @param {string} name
   */
  _getTopic(name) {
    if (!this.topics[name]) {
      this.topics[name] = [];
    }
    return this.topics[name];
  }
}

//this is just to make it available in the devtools for debugging (usable by global.PubSub). It can be removed later
global.PubSub = PubSub;

/**
 * Encapsulates the subscription to a topic
 */
class Subscription {
  callback;

  constructor(callback) {
    this.callback = callback;
  }

  triggerCallback(data) {
    this.callback.call(null, ...data);
  }
}
